import React, {useState, useEffect} from 'react';
import {BeatLoader} from "react-spinners";
import {useDispatch, useSelector} from "react-redux";
import {getGlobal} from "../../api/app";
import {Link} from "react-router-dom";
import Footer from "../../components/layout/Footer";
import {truncate} from "../../utils/trunate";
import SEO from "../../components/layout/Seo";

const Explore = () => {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const {courses, category, books, products} = useSelector((state) => state.app);

    useEffect(() => {
        dispatch(getGlobal()).then((response) => {
            if(!response.error) {
                setLoading(false);
            }
        });
    }, []);

    return (
        <div>
            <SEO title="ZStudy | Explore" description="Explore courses at zstudy" />
            {loading ? <div className="h-screen flex item-center justify-center">
                <div className="loader">
                    <BeatLoader color="#003074" size={20} />
                </div>
            </div> : <div>
                <div className="container">
                    <div className="sm:my-4 my-3 flex items-end justify-between pt-3">
                        <h2 className="text-2xl font-semibold"> Featured Courses </h2>
                    </div>

                    <div className="relative -mt-3" uk-slider="finite: true">

                        <div className="uk-slider-container px-1 py-3">
                            <ul className="uk-slider-items uk-child-width-1-1@m uk-grid">
                                {courses?.slice(0).reverse().map((item, index) => {
                                    return(
                                        <li key={index}>
                                            <div className="bg-white shadow-sm rounded-lg uk-transition-toggle md:flex">
                                                <Link to={`course/${item._id}`} className="md:w-5/12 md:h-60 h-40 overflow-hidden rounded-l-lg relative">
                                                    <img src={item.cover.uri} alt="" className="w-full h-full absolute inset-0 object-cover"/>
                                                    <img src="assets/images/icon-play.svg" className="w-16 h-16 uk-position-center uk-transition-fade" alt=""/>
                                                </Link>
                                                <div className="flex-1 md:p-6 p-4 my-auto">
                                                    <div className="font-semibold line-clamp-2 md:text-xl md:leading-relaxed">{item.name}</div>
                                                    <div className="mt-2 md:block hidden">
                                                        <p className="line-clamp-2">{item.description}</p>
                                                    </div>
                                                    <div className="font-semibold mt-3"> ZStudy</div>
                                                    <div className="mt-1 flex items-center justify-between">
                                                        <div className="flex space-x-2 items-center text-sm pt-2">
                                                            <div> {item.category.name}</div>
                                                            <div>·</div>
                                                            <div> {item.contents.length} lectures</div>
                                                        </div>
                                                        {/*<div className="text-lg font-semibold"> K{item.packages[0].amount}</div>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        <a className="absolute bg-white uk-position-center-left -ml-3 flex items-center justify-center p-2 rounded-full shadow-md text-xl w-11 h-11 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="previous"> <i className="icon-feather-chevron-left"></i></a>
                        <a className="absolute bg-white uk-position-center-right -mr-3 flex items-center justify-center p-2 rounded-full shadow-md text-xl w-11 h-11 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i className="icon-feather-chevron-right"></i></a>

                    </div>

                    <div className="sm:my-4 my-3 flex items-end justify-between pt-3">
                        <h2 className="text-2xl font-semibold"> Popular Courses </h2>
                        <a href="#/courses" className="text-blue-500 sm:block hidden"> See all </a>
                    </div>

                    <div className="mt-3">

                        <h4 className="py-3 border-b font-semibold text-grey-700  mx-1 mb-4" hidden>
                            <ion-icon name="star"></ion-icon>
                            Featured today
                        </h4>

                        <div className="mt-3">

                            <h4 className="py-3 border-b font-semibold text-grey-700  mx-1 mb-4" hidden>
                                <ion-icon name="star"></ion-icon>
                                Featured today
                            </h4>

                            <div className="relative" uk-slider="finite: true">

                                <div className="uk-slider-container px-1 py-3">

                                    <ul className="uk-slider-items uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-small uk-grid">
                                        {courses.length && courses.map((item, index) => {
                                            return(
                                                <li key={index}>
                                                    <Link to={`course/${item._id}`} className="uk-link-reset">
                                                        <div className="card uk-transition-toggle">
                                                            <div className="card-media h-40">
                                                                <div className="card-media-overly"></div>
                                                                <img src={item.cover.uri} alt="cover" className=""/>
                                                                <span className="icon-play"></span>
                                                            </div>
                                                            <div className="card-body p-4">
                                                                <div className="font-semibold line-clamp-2">{truncate(item.name, 28)}</div>
                                                                <div className="flex space-x-2 items-center text-sm pt-3">
                                                                    <div> {truncate(item.category.name, 15)}</div>
                                                                    <div> ·</div>
                                                                    <div> {item.contents.length} lectures</div>
                                                                </div>
                                                                <div className="pt-1 flex items-center justify-between">
                                                                    <div className="text-sm font-medium"> ZStudy</div>
                                                                    {/*<div className="text-lg font-semibold"> K{item.packages[0].amount}</div>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>

                                    <a className="absolute bg-white top-1/4 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="previous"> <i className="icon-feather-chevron-left"></i></a>
                                    <a className="absolute bg-white top-1/4 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i className="icon-feather-chevron-right"></i></a>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="sm:my-4 my-3 flex items-end justify-between pt-3">
                        <h2 className="text-2xl font-semibold"> Categories </h2>
                        <a href="#/category" className="text-blue-500 sm:block hidden"> See all </a>
                    </div>

                    {category.length && category.map(() => {
                        return(
                            <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4 mt-3">
                                <Link to={`course-list/${category[0]?._id}`} className="rounded-md overflow-hidden relative w-full lg:h-48 h-40">
                                    <div className="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10"></div>
                                    <img src={category[0]?.cover_uri} className="absolute w-full h-full object-cover" alt=""/>
                                    <div className="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> {category[0]?.name}</div>
                                </Link>
                                <Link to={`course-list/${category[1]?._id}`} className="rounded-md overflow-hidden relative w-full lg:h-48 h-40">
                                    <div className="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10"></div>
                                    <img src={category[1]?.cover_uri} className="absolute w-full h-full object-cover" alt=""/>
                                    <div className="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> {category[1]?.name}</div>
                                </Link>
                                <Link to={`course-list/${category[2]?._id}`} className="rounded-md overflow-hidden relative w-full lg:h-48 h-40">
                                    <div className="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10"></div>
                                    <img src={category[2]?.cover_uri} className="absolute w-full h-full object-cover" alt=""/>
                                    <div className="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> {category[2]?.name}</div>
                                </Link>
                                <Link to={`course-list/${category[3]?._id}`} className="rounded-md overflow-hidden relative w-full lg:h-full h-40 lg:row-span-2">
                                    <div className="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10"></div>
                                    <img src={category[3]?.cover_uri} className="absolute w-full h-full object-cover" alt=""/>
                                    <div className="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> {category[3]?.name}</div>
                                </Link>
                                <Link to={`course-list/${category[4]?._id}`} className="rounded-md overflow-hidden relative w-full lg:h-48 h-40 lg:col-span-2"><div className="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10"></div>
                                    <img src={category[4]?.cover_uri}  className="absolute w-full h-full object-cover" alt=""/>
                                    <div className="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> {category[4]?.name}</div>
                                </Link>
                                <Link to={`course-list/${category[5]?._id}`} className="rounded-md overflow-hidden relative w-full lg:h-48 h-40">
                                    <div className="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10"></div>
                                    <img src={category[5]?.cover_uri} className="absolute w-full h-full object-cover" alt=""/>
                                    <div className="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> {category[5]?.name}</div>
                                </Link>
                            </div>
                        )
                    })}

                    {books.length > 0 ? <>
                        <div className="sm:my-4 my-3 flex items-end justify-between pt-3">
                            <h2 className="text-2xl font-semibold"> Latest Books </h2>
                            {books.length > 9 ? <a href="#/books" className="text-blue-500 sm:block hidden"> See all </a> : null}
                        </div>

                        <div className="relative" uk-slider="finite: true">
                            <div className="uk-slider-container px-1 py-3">
                                <ul className="uk-slider-items uk-child-width-1-6@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid text-sm font-medium text-center">
                                    {books.map((item) => {
                                        return(
                                            <li>
                                                <div className="relative overflow-hidden bg-white shadow-sm md:rounded-lg rounded-md">
                                                    <a href={`#/book/${item._id}`}>
                                                        <img src={item.cover_uri} alt="" className="w-full h-52 object-cover"/>
                                                        <div className="p-3 truncate">{item.name}</div>
                                                    </a>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>

                                <a className="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="previous"> <i className="icon-feather-chevron-left"></i></a>
                                <a className="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i className="icon-feather-chevron-right"></i></a>

                            </div>
                        </div>
                    </> : null}

                    {products.length > 0 ? <>
                        <div className="sm:my-4 my-3 flex items-end justify-between pt-3">
                            <h2 className="text-2xl font-semibold"> STEM Kits </h2>
                            {products.length > 9 ? <a href="#/products" className="text-blue-500 sm:block hidden"> See all </a> : null}
                        </div>

                        <div className="relative uk-slider" uk-slider="finite: true">
                            <div className="uk-slider-container px-1 py-3">
                                <ul className="uk-slider-items uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
                                    {products.map((item) => {
                                        return (
                                            <li tabIndex="-1" className="uk-active">
                                                <a href={`#/product/${item._id}`}>
                                                    <div className="card">
                                                        <div className="card-media h-40">
                                                            <div className="card-media-overly"></div>
                                                            <img src={item.image_uri} alt="" className="max-h-96 w-full object-contain"/>
                                                            <span className="absolute bg-white px-2 py-1 text-sm rounded-md m-2"> Label</span>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                                                                K{item.amount}
                                                            </div>
                                                            <div className="text-xs font-semibold uppercase text-yellow-500">In-Stock
                                                            </div>
                                                            <div className="ext-lg font-medium mt-1 t truncate"> {item.name}</div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>

                                <a className="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="#" uk-slider-item="previous"> <i className="icon-feather-chevron-left"></i></a>
                                <a className="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i className="icon-feather-chevron-right"></i></a>
                            </div>
                        </div>
                        </> : null}
                </div>

                <Footer/>
            </div>}
        </div>
    );
};

export default Explore;
